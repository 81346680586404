

function Footer () {
     return (
        <div>
            <div className="bg-[#000511] ">
                <div className="flex justify-center align-center m-auto pb-7">
                <span className="font-bold text-transparent text-2xl p-2.5 bg-clip-text bg-gradient-to-r from-[#fd746c] to-[#ff9068]">Bought To You By</span>
                <span className="font-bold text-transparent text-2xl py-2.5 bg-clip-text bg-gradient-to-r from-[#fff] to-[#fff]">COE(Iot)</span>
                    
                    
                </div>
            </div>
        </div>
     );
}

export default Footer;