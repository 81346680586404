import React, { useState } from 'react';
import Footer from '../components/Footer';

const State = () => {
    const [Q1, setState] = useState([
        {
            question: 'Smart City',
            answer1: '1.)<strong> Traffic Management:</strong> Develop an IoT and edge computing solution to optimize traffic flow, reduce congestion and enhance safety. Sensors and cameras collect real-time data, and machine learning predicts congestion hotspots. Edge computing provides actionable insights to drivers, traffic operators and city officials.',
            answer2: '2.) <strong>Waste Management:</strong> Develop an IoT and edge computing solution for waste management that uses sensors to optimize collection routes and machine learning to improve recycling rates which integrates with smart city infrastructure for real-time data on waste management through mobile apps and dashboards.',
            answer3: '3.)<strong> Smart Parking:</strong> Develop an IoT solution for smart parking that optimizes space utilization and enhances the driver experience. Sensors should detect vehicle presence and machine learning should predict parking availability. There should be a easy to use management dashboard for operators. ',
            answer4: '4.) <strong>Water Management: </strong>Build a smart water management solution that optimizes real-time water usage in a city. Sensors  should measure flow rates, quality, and pressure, while machine learning algorithms should predict supply and demand while detecting leaks and thefts and the system should provide real-time alerts to authorities. ',
            answer5: '<br/>5.) <strong>Public Transportation:</strong> Create hardware solutions that can improve public transportation, such as smart bus stops, real-time transit information systems, or ticketing systems. ',
            answer6: '<br/>6.) <strong>Community Engagement:</strong>Build devices or systems that can improve community engagement and communication with local government or city services.',
            open: false
        },
        {
            question: 'Agriculture',
            answer1: '1.) <strong>Precision Farming: </strong>Develop an IIoT solution for precision farming that uses sensors to collect real-time data on soil, weather, and chemical levels. Machine learning algorithms provide actionable insights to farmers, including alerts when chemical levels are unsafe. The solution tracks farm equipment movement and ensures safety protocols are followed. ',
            answer2: '2.) <strong>Livestock Monitoring: </strong>Design hardware that can help farmers monitor and manage livestock, such as smart collars for tracking animal health or location.',
            answer3: "3.) <strong>Animal Hazard Prevention : </strong>Develop an IoT solution to prevent animal accidents on roads and railways. Sensors and cameras detect animal presence, and machine learning predicts crossing probability to provide real-time alerts and proactive measures. Cost-effective and eco-friendly, it's deployable in urban and rural areas while ensuring animal safety.",
            answer4: '4.) <strong>Plant Breeding: </strong>Develop hardware that can assist farmers in plant breeding, such as sensors for measuring plant traits or developing new crops through gene editing.',
            open: false
        },
        {
            question: 'General',
            answer1: '1.) <strong>Smart Homes:</strong> Develop an IoT and edge computing solution for a smart home that optimizes energy consumption, enhances security, and personalizes homeowner experience. Sensors collect real-time data and machine learning identifies areas for improvement. The solution should be intuitive and easy to install.',
            answer2: '2.) <strong>Healthcare and Wellness: </strong> Develop an IoT and edge computing based solution that promotes health and wellness by monitoring key health metrics and providing personalized recommendations to users.',
            answer3: '3.) <strong>Accessibility: </strong> Create an IoT and edge computing solution for accessibility in public spaces. Sensors and cameras detect people with disabilities and provide real-time information about accessibility features. Edge computing algorithms guide users to these features and alert facility managers when repairs are needed. Cost-effective and deployable in parks, buildings, and transportation systems, it prioritizes user privacy and data security.',
            answer4: '4.) <strong>Environmental Monitoring:</strong> Create hardware that can detect and measure environmental factors such as air quality, water quality, or weather conditions.',
            answer5: '<br/>5.) <strong>Disaster Relief & Response:</strong> Develop an edge computing solution that can assist in disaster response efforts. The solution should be able to collect data from various sources, including social media and IoT sensors, and provide real-time insights to first responders.',
            answer6: '<br/>6.) <strong>Education and Learning: </strong>Build devices or systems that enhance education or learning.',
            answer7: '<br/>7.) <strong>Entertainment: </strong>Develop hardware prototypes that enhance or create new forms of entertainment.',
            answer8: '<br/>8.) <strong>Assistive Technology: </strong> Create hardware solutions that can assist people with disabilities or mobility challenges, such as wearables, robotic assistants, or communication devices.',
            open: false
        },
        {
            question: 'IIOT 4.0',
            answer1: '1.) <strong>Predictive Maintenance: </strong> Develop an edge computing solution that can predict equipment failures and perform predictive maintenance. The solution should use machine learning algorithms to analyze sensor data and provide real-time insights into the health of equipment',
            answer2: '2.) <strong>Supply Chain Optimization:</strong> Develop an edge computing solution that can optimize supply chain operations. The solution should be able to collect data from various sources, including RFID tags and IoT sensors, and provide real-time insights to logistics managers.',
            answer3: '3.) <strong>Automated defect detection:</strong> Develop an IIoT solution that can automatically detect defects in a specific product, such as a printed circuit board or a mechanical component. The solution should use machine learning algorithms to analyze data from sensors and cameras, and provide real-time feedback to the manufacturing process.',
            open: false
        },
        
        {
            question: 'Space',
            answer1: '1.) Assume you are sending a nanosatellite to space, so develop an IoT satellite that can predict and monitor solar flares, which can pose a danger to satellites and spacecraft.',
            answer2: '2.) Create an IoT solution that can monitor the health and safety of space tourists during their trip, providing real-time data to doctors and mission control.',
            answer3: '3.) Assume you are sending a nanosatellite to space, so develop an IoT satellite that can track and monitor space debris and predict potential collisions with satellites or spacecraft.',
            answer4: '4.) Assume you are sending a nanosatellite to space, so develop an edge node that can automatically analyze satellite images to identify changes in land use, weather patterns, or other phenomena.',
            open: false
        },

    ]);

    const toggleState = (index) => {
        setState(Q1.map((item, i) => {
            if (i === index) {
                item.open = !item.open;
            } else {
                item.open = false;
            }

            return item;
        }));
    }

    return (
        <section id="FAQ" className="py-10 bg-[#000511] w-full md:h-screen sm:py-16 lg:py-20 ">
            <div className="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">
                <div className=" mx-auto text-center">
                    <h2 className="text-3xl font-bold leading-tight text-white sm:text-4xl lg:text-5xl">Problem Statements (IoT Hackathon)</h2>
                    
                </div>

                <div className="max-w-3xl mx-auto mt-8 space-y-4 md:mt-16">
                    {Q1.map((item, index) => (
                        <div key={index} className="transition-all duration-200 bg-zinc-900 rounded-lg shadow-lg shadow-neutral-900 cursor-pointer hover:bg-zinc-900">
                            <button type="button" className="flex items-center justify-between w-full px-4 py-5 sm:p-6" onClick={() => toggleState(index)}>
                                <span className="flex text-lg font-semibold text-white"> {item.question} </span>

                                <svg className={`w-6 h-6 text-white ${item.open ? 'rotate-180' : ''}`} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
                                </svg>
                            </button>

                            <div className={`${item.open ? 'block' : 'hidden'} px-4 pb-5 sm:px-6 sm:pb-6`}>
                                <p className='text-' dangerouslySetInnerHTML={{ __html: item.answer1 }}></p>
                                <br/>
                                <p className='text-' dangerouslySetInnerHTML={{ __html: item.answer2 }}></p>
                                <br/>
                                <p className='text-' dangerouslySetInnerHTML={{ __html: item.answer3 }}></p>
                                <br/>
                                <p className='text-' dangerouslySetInnerHTML={{ __html: item.answer4 }}></p>
                                
                                <p className='text-' dangerouslySetInnerHTML={{ __html: item.answer5 }}></p>
                                
                                <p className='text-' dangerouslySetInnerHTML={{ __html: item.answer6 }}></p>
                                
                                <p className='text-' dangerouslySetInnerHTML={{ __html: item.answer7 }}></p>
                                
                                <p className='text-' dangerouslySetInnerHTML={{ __html: item.answer8 }}></p>
                                
                                <p className='text-' dangerouslySetInnerHTML={{ __html: item.answer9 }}></p>
                                
                                <p className='text-' dangerouslySetInnerHTML={{ __html: item.answer10 }}></p>
                                
                                <p className='text-' dangerouslySetInnerHTML={{ __html: item.answer11 }}></p>
                            </div>
                        </div>
                    ))}
                </div>
<br/>
<br/>
<br/>
            </div>
            <Footer/>
        </section>
    );
}

export default State;
