
import State from "../src/layouts/State"


function App() {
  return (
    <div className="">

    <State/>


   </div>
  )
  }
  
export default App;
